import React from 'react';
import Button from 'react-bootstrap/Button';
import {Container,Form,Col,Row} from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import {db,firebase} from './Firestore';
import { useState,useEffect } from 'react';
import useGlobal from './../Store';

const Header = (props) => {

    const history = useHistory();
    const [isLoggedIn,setIsLoggedIn] = useState(false)
    const [currentUserEmail,setCurrentUserEmail] = useState('')
    const [globalState, globalActions] = useGlobal();

    useEffect(()=>{
        setCurrentUserEmail(props.email)
    },[])

    const onClickLogin = () => {
        console.log('login')
        history.push('/login')
    } 
    const onClickSignup = () => {
        console.log('signup')
        history.push('/register')
    } 
    const onClickLogout =() => {
        console.log('logout')
        firebase.auth().signOut()
        globalActions.setUserEmail('')
        history.push('/login')
    }
    const onClickHome = () => {
        history.push('/')
    }
    const headerButtons = () => {

        if(currentUserEmail === 'temp@racetrackermap.com') {
            return( 
                <Col style={{display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
                    <Button variant="primary" onClick={onClickHome} style={{backgroundColor:'transparent',borderColor:'transparent'}}>
                        Home
                    </Button>
                    <Button variant="primary" onClick={onClickLogin} style={{backgroundColor:'transparent',borderColor:'transparent'}}>
                        Login
                    </Button>
                    <Button variant="primary" onClick={onClickSignup} style={{marginLeft:'0px'}}>
                        Signup
                    </Button>
                </Col>
            )
        }
        else if(currentUserEmail === '') {
            return( 
                <Col style={{display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
                    <Button variant="primary" onClick={onClickHome} style={{backgroundColor:'transparent',borderColor:'transparent'}}>
                        Home
                    </Button>
                    <Button variant="primary" onClick={onClickLogin} style={{backgroundColor:'transparent',borderColor:'transparent'}}>
                        Login
                    </Button>
                    <Button variant="primary" onClick={onClickSignup} style={{marginLeft:'20px'}}>
                        Signup
                    </Button>
                </Col>
            )
        } 
        else{
            return( 
                <Col style={{display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
                    <Button variant="primary" onClick={onClickHome} style={{backgroundColor:'transparent',borderColor:'transparent'}}>
                        Home
                    </Button>
                </Col>
            )
        } 
        
    }
   return ( 
    <Container style={{display:'flex',flexDirection:'column',width:'100%',height:'100%',backgroundColor:'gray',paddingLeft:'10px',color:'white',marginBottom:'10px'}}>
        <Row style={{alignItems:'center'}}>
            <Col>
                <h2>{props.headerText}</h2>
            </Col>
            {headerButtons()}
        </Row>
    </Container>
   );
}

export default Header;