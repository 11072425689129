import React, { StrictMode } from 'react';
import { useHistory } from "react-router-dom";
import { useState,useEffect } from 'react';
import useGlobal from './Store';
import {db,firebase} from './modules/Firestore';
import Button from 'react-bootstrap/Button';
import { Form,Col,Row,ButtonGroup,ToggleButton } from 'react-bootstrap';

import {Container} from 'react-bootstrap';
import DateTimePicker from 'react-datetime-picker';
import { firestore } from 'firebase';
import Header from './modules/Header';
import SimpleMap from './Map';
import GPX from 'gpx-parser-builder';

function CreateEvent(props) {
    
    var nameInput = React.createRef();
    var locInput = React.createRef();
    var gpxInput = React.createRef();

    const [date,setDate] = useState(new Date());
    const [name,setName] = useState();
    const [location,setLocation] = useState()
    const history = useHistory();
    const [gpxData,setGpxData] = useState();
    const [polyData,setPolyData] = useState('')
    const [mapCenter,setMapCenter] = useState(0);
    const [mapLoaded,setMapLoaded] = useState(false);
    const [searchedUser,setSearchedUser] = useState('');
    const [eventData,setEventData] = useState([]);

    const [radioValue, setRadioValue] = useState('1');

    const radios = [
        { name: 'Public', value: '1' },
        { name: 'Private', value: '2' },
    ];
    useEffect(() => {
       //loadEventData();
       document.title = "RacetrackerMap";
    },[]); 
    const onChange = date => setDate(date);

    const onClick = async () => {
        console.log('on create event');
        const data = {
            userData:[],
            name:nameInput.current.value + ' ' + locInput.current.value,
            date:date,
            gpxData:gpxInput.current.value,
            type:radioValue

        };
        const dbRef = db.collection("events");
       // console.log(dbRef)
        const userId = props.location.state.userId;

        var item = await dbRef.doc(userId).set({
            name:'eventCollection'
        })

       // console.log(item)
        await dbRef.doc(userId).collection('eventItems').add(data);
       // item.collection('eventItems').add(data);

        history.push('/main/'+userId)
    }
   
    const onChangeText = () => {
        console.log(nameInput.current.value);
    }
    const onMapRefresh = () => {
        const gpx = GPX.parse(gpxInput.current.value);
        var tempGpx = [];

        if(gpx != undefined) {
           
            const trk = gpx.trk[0];
            const trkSeg = trk.trkseg[0];

            for(var i = 0; i < trkSeg.trkpt.length; i++) {
                const wpt = trkSeg.trkpt[i];
               // console.log(wpt.$.lat + " " + wpt.$.lon);
                const item = {'lat':parseFloat(wpt.$.lat),'lng':parseFloat(wpt.$.lon)};
                tempGpx.push(item);
            }
            
            const center = {
                lat:tempGpx[0].lat,
                lng:tempGpx[0].lng
            }

            setPolyData(tempGpx);
            setMapCenter(center);
            setEventData([]);
            setSearchedUser('');
            setMapLoaded(true);
        }
    }

    return (
        <Container >
            <Header headerText="RaceTracker"></Header>
          
           
            <Form>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Event Name</Form.Label>
                    <Form.Control type="input" placeholder="Event Name" ref={nameInput}  value={name}/>
                    <ButtonGroup toggle style={{marginTop:'20px'}}>
                        {radios.map((radio, idx) => (
                        <ToggleButton
                            key={idx}
                            type="radio"
                            variant="secondary"
                            name="radio"
                            value={radio.value}
                            checked={radioValue === radio.value}
                            onChange={(e) => setRadioValue(e.currentTarget.value)}
                        >
                            {radio.name}
                        </ToggleButton>
                        ))}
                    </ButtonGroup>
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Event location</Form.Label>
                    <Form.Control type="input" placeholder="Location" ref={locInput} value={location} />
                </Form.Group>
               
                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Event Date</Form.Label>
                    <br/>
                    <DateTimePicker
                        onChange={onChange}
                        value={date}
                        clearIcon = {null}/>
                </Form.Group>

                <Form.Group controlId="gpxData">
                    <Form.Label>Race Route (GPX data)</Form.Label>
                    <Form.Control as="textarea" rows={3} ref={gpxInput} value = {gpxData} />
                </Form.Group>
                <Row style={{marginBottom:'20px'}}>
                       
                        <Button variant="primary" onClick={onClick}  style={{marginLeft:'20px'}}>
                            Create Event
                        </Button>

                        <Button variant="primary" onClick={onMapRefresh} style={{marginLeft:'50px'}}>
                            View Route
                        </Button>
                </Row>

                <Form.Group controlId="map">                
                <SimpleMap data={eventData} searchedUser={searchedUser} center={mapCenter} polyData={polyData}></SimpleMap> 
                </Form.Group>

                </Form>
        </Container>
    );
    
}

export default CreateEvent;
