import React, { StrictMode } from 'react';
import { useHistory } from "react-router-dom";
import { useState,useEffect } from 'react';
import useGlobal from './Store';
import {db,firebase} from './modules/Firestore';

import SimpleMap from './Map';
import Button from 'react-bootstrap/Button';


import {Container,Form,Col,Row,DropdownButton,Dropdown} from 'react-bootstrap';
import Header from './modules/Header';
import GPX from 'gpx-parser-builder';

function EventDetails(props) {

    var nameInput = React.createRef();
    const [name,setName] = useState();
    const [eventData,setEventData] = useState([]);
    const [mapCenter,setMapCenter] = useState(0);
    const [noData,setNoData] = useState(true);
    const [searchedUser,setSearchedUser] = useState('');
    const [isMultiEvents,setIsMultiEvents] = useState(false)    
    const [multiEventData,setMultiEventData] = useState(false);
    const [globalState, globalActions] = useGlobal();
    const history = useHistory();
    const [currentEvent,setCurrentEvent] = useState(null)
    const [polyData,setPolyData] = useState('')
    const [phNumber,setPhoneNumber] = useState('')
    const [emNumber,setEmergencyNumber] = useState('')

    useEffect(() => {
        document.title = "RacetrackerMap";
        setIsMultiEvents(props.match.params.isMultiEvent)
        
        loadEventData()

        
    },[]); 

    const loadEventData = () => {

        const eventId = props.location.state.key;
        const userId = props.location.state.userId;
        const multiEvent = props.location.state.isMultiEvents
        setIsMultiEvents(multiEvent)

        if(userId === '' || eventId === '') {
            setNoData(true);
            return;
        }
        else {
            setNoData(false);
        }
        const dbRef = db.collection("events");
        dbRef.doc(userId).collection('eventItems').doc(eventId).get().then((snap)=>{

            if (snap.empty) {
                console.log('No matching documents.');
                return;
            }
            else {
                console.log(snap.data());
                const data = snap.data();
                const userData = data['userData'];
                var gpxData = data['gpxData'];
                var tempGpx = [];

                if(gpxData === null || gpxData === undefined || gpxData.length === 0) {
                    gpxData = [];
                }
                else {
                    const gpx = GPX.parse(gpxData);
                    console.log(gpx);

                    if(gpx != undefined) {
                        const trk = gpx.trk[0];
                        const trkSeg = trk.trkseg[0];

                        
                        for(var i = 0; i < trkSeg.trkpt.length; i++) {
                            const wpt = trkSeg.trkpt[i];
                            console.log(wpt.$.lat + " " + wpt.$.lon);
                            const item = {'lat':parseFloat(wpt.$.lat),'lng':parseFloat(wpt.$.lon)};
                            tempGpx.push(item);
                        }
                        setPolyData(tempGpx);
                    }
                }

                if(userData == null || userData == 'undefined' || userData.length == 0) {
                   // setNoData(true);
                   // return;
                }
                const user = userData[0];
                setCurrentEvent(data)

                console.log(userData);
                setEventData(userData);

                if(user != undefined) {
                    const center = {
                        lat:user.location.latitude,
                        lng:user.location.longitude
                    }
                    setMapCenter(center);
                }
                else if(tempGpx.length > 0){
                    const center = {
                        lat:tempGpx[0].lat,
                        lng:tempGpx[0].lng
                    }
                    setMapCenter(center);
                }
            }  
        })
    }
    const onDropdownSelect = (key,obj) => {
        console.log(key);
        if(key !== '1' && typeof(key) == 'string') {
          globalActions.setEventId(key);
          history.push('/eventDetails/false',{key:key,userId:props.match.params.userId});
        }
       
  
    };
    const loadMultiEventData = () => {

        const eventId = props.location.state.key;
        const userId = props.location.state.userId;
        const multiEvent = props.location.state.isMultiEvents
        var array = Array()
        setIsMultiEvents(multiEvent)

        if(userId === '' || eventId === '') {
            setNoData(true);
            return;
        }
        else {
            setNoData(false);
        }
        const dbRef = db.collection("events").get().then((snap) => {

            snap.forEach((doc) => {
                dbRef.doc(doc.id).collection('eventItems').get().then((snap)=>{
                    snap.forEach((eventItem) => {
                        dbRef.doc(doc.id).collection('eventItems').doc(eventItem.id).get().then((eventItemDoc) => {
                            var item = {
                                userId:doc.id,
                                userData:eventItemDoc.data()['userData'],
                                name:eventItemDoc.data()['name'],
                                location:eventItemDoc.data()['location'],
                                eventItemId:eventItem.id,
                                phoneNumber:eventItemDoc.data()['phoneNumber'],
                                emergencyNumber:eventItemDoc.data()['emergencyNumber']
                            }
                            array.push(item)
                        })
                    }) 
                    
            })  
        })       
            setMultiEventData(array)
        }) 
       
    }
    const onClickSearch = () => {
       
        setSearchedUser(name)  
        getUserDataByName(name)    

    };
    const onClear = () => {
        setSearchedUser(undefined);
        setPhoneNumber(undefined);
        setEmergencyNumber(undefined);
    }
    const getUserDataByName = (user) => {
        eventData.map((item) => {
            if(item.id === user) {
                setPhoneNumber(item.phoneNumber);
                setEmergencyNumber(item.emergencyNumber);
            }
        });
    }

    const onClick = () => {
        history.goBack()
    };
    const onDelete = () => {
        const eventId = props.location.state.key;
        const userId = props.location.state.userId;
        const dbRef = db.collection("events");
        dbRef.doc(userId).collection('eventItems').doc(eventId).delete();
        history.goBack()
    }

    if(noData == false) {
    return (
        <Container >
        <Header headerText="RaceTracker"></Header>
        <Row >  
            <Col sm>  
                <Button variant="primary" onClick={onClick} style={{height:'40px', marginBottom:'10px',width:'200px'}}>
                    All Events
                </Button>
            </Col>

            <Col sm>
                <Form >
                    <Form.Row  style={{height:'40px', marginBottom:'10px'}}>
                        <Col>
                            <Form.Control  type="input" placeholder="User Number" onChange={(e)=>{setName(e.target.value)}} value={name} />
                        </Col>
                        <Col>
                            <Button variant="primary" onClick={onClickSearch}>
                                Search
                            </Button>
                        </Col>
                        <Col>
                            <Button variant="primary" onClick={onClear}>
                                Clear
                            </Button>
                        </Col>

                        <Col>
                            <Button variant="primary" onClick={onDelete}>
                                Delete
                            </Button>
                        </Col>
                    </Form.Row>
                </Form>
                
            </Col>
            
        </Row>

        { searchedUser &&
            <Row>
                <Col>
                        <label style={{ fontSize:'16px'}}>User :</label> <label style={{fontSize:'25px'}}>{searchedUser}</label>
                </Col>
            </Row>
        }
        
        { (phNumber || emNumber) &&
            <Col>
            
                <Col>
                        <label style={{ fontSize:'16px'}}>Phone Number:</label> <label style={{fontSize:'16px'}}>{phNumber}</label>
                </Col>
                <Col>
                        <label style={{ fontSize:'16px'}}>Emergency Number:</label> <label style={{fontSize:'16px'}}>{emNumber}</label>
                </Col>
            </Col>
        }
        
        { currentEvent &&
            <Col>
                <Col>
                    <label style={{ fontSize:'16px'}}>Event:</label> <label style={{fontSize:'16px'}}>{currentEvent.name}</label>
                </Col>
                <Col>
                    <label style={{ fontSize:'16px'}}>People Count:</label> <label style={{fontSize:'16px'}}>{eventData.length}</label>
                </Col>
                
            </Col>
        }
        <SimpleMap data={eventData} searchedUser={searchedUser} center={mapCenter} polyData={polyData}></SimpleMap> 
        </Container>
    );
    }
    else {
        return (
        <Container style={{display:'flex',flexDirection:'column',alignItems:'center' }}>

        <Header headerText="RaceTracker"></Header>

            <Button variant="primary" onClick={onClick} style={{ marginTop:'10px',marginBottom:'10px', height: '40px', width: '30%',justifyContent:'center' }}>
                    All Events
            </Button>
            <h5>No data available for this event.</h5>
        </Container>
        );
    }
}


export default EventDetails;
