import firebase, { firestore } from 'firebase';  


const config = {
  // copy and paste your firebase credential here
  apiKey: "AIzaSyAcD3KStgCbdv7ftl8LtbAk0T2oMwroCko",
  //authDomain: "racetrackermaps-1277b.firebaseapp.com",
  authDomain: "racetrackermap.com",
  databaseURL: "https://racetrackermaps-1277b.firebaseio.com",
  projectId: "racetrackermaps-1277b",
  storageBucket: "racetrackermaps-1277b.appspot.com",
  messagingSenderId: "782741558363",
  appId: "1:782741558363:web:04d76de7b53a9b0f3f9b7e",
  measurementId: "G-F47SRX0HFR"
};

/*const config = {
  // copy and paste your firebase credential here
  apiKey: "AIzaSyAPVdSp3g9cBjmbtdnNATigBLJvjaxf2j4",
  authDomain: "test1-4f9be.firebaseapp.com",
  databaseURL: "https://test1-4f9be.firebaseio.com",
  projectId: "test1-4f9be",
  storageBucket: "test1-4f9be.appspot.com",
  messagingSenderId: "352453344615",
  appId: "1:352453344615:web:a915519463af59b85503c2"
};*/

firebase.initializeApp(config);
const db = firebase.firestore();

export { db};
export {firebase};