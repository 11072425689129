import React from 'react';
import { useHistory } from "react-router-dom";
import { useState,useEffect } from 'react';
import {firebase} from './modules/Firestore'


import Jumbotron from 'react-bootstrap/Jumbotron';
import Toast from 'react-bootstrap/Toast';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { Form,Col } from 'react-bootstrap';
import useGlobal from './Store';
import Global from './Global';
import { auth } from 'firebase';
import Header from './modules/Header';


function LoginView() {

 // const [username,setUsername] = useState('admin@racetracer.com');  
 // const [pwd,setPwd] = useState('admin12345');
  const [username,setUsername] = useState('');  
  const [pwd,setPwd] = useState('');
  const history = useHistory();
  const [globalState, globalActions] = useGlobal();
  const [userId,setUserId] = useState('');

  useEffect(()=>{
   // firebase.auth().signOut();
    document.title = "RacetrackerMap";
  });
  const submitHandler = () => {

    console.log(username+' '+pwd);
    //firebase.auth().signOut();

    firebase.auth().onAuthStateChanged((user) => {
        if(user) {
          console.log("user logged in");
        }
     });

    firebase.auth().signOut().then(() => {
        console.log("logged out");

        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(function() {
          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.
          // ...
          // New sign-in will be persisted with session persistence.
          return  firebase.auth().signInWithEmailAndPassword(username,pwd).then((user) => {
            console.log(user);
          }).catch(function(error) {
            // Handle Errors here.
            var errorMessage = error.message;
            console.log(errorMessage);
            alert(errorMessage);
            // ...
          });
        })
        .catch(function(error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log(errorMessage);
        });

    }).catch(() => {
        console.log("logout failed");
    });

    

    firebase.auth().onAuthStateChanged(async userAuth => {
      if(userAuth) {
        console.log('logged in');
        let userId = userAuth.uid;
        console.log('Userid:',userId);
        globalActions.setUserId(userId);
        globalActions.setUserEmail(userAuth.email)
        history.push('/main/' + userId);
      }
    });
    //history.push('/main');
   
  }

const onRegister = () => {
  console.log("register");
  history.push("/register");
}
  
return (
     
  <Container>
  <Header headerText="RaceTracker" email={globalActions.email}></Header>
  <Col>
  <Jumbotron stype = {{padding:'100px'}}>
    <Form>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>User name / Email</Form.Label>
        <Form.Control type="email" value={username} placeholder="Enter email" onChange={(e)=>{setUsername(e.target.value)}} />
        <Form.Label>Password</Form.Label>
        <Form.Control type="password" value={pwd} placeholder="Enter Password" onChange={(e)=>{setPwd(e.target.value)}}/>
      </Form.Group>
      <div className="d-flex justify-content-center">
        <Button style={styles.button} onClick={submitHandler}>
          Login
        </Button>
        <Button variant="outline-primary" style={styles.buttonOutlined} onClick={onRegister}>
          Register
        </Button>
      </div>
    </Form>
    </Jumbotron>
    </Col>
    
  </Container>
);
  
}
const styles = {
  
  mainDiv:{
    width:'100%',
    height:'100%',
  },
  mainHeading:{
    fontSize:25,
    fontWeight:'600',
    color:'black',
    display:'block',
    marginTop:'30px',
    textAlign:'left'
  },
  heading : {
    fontSize:20,
    fontWeight:'600',
    color:'#21A6FC',
    display:'block',
    marginTop:'30px',
    textAlign:'center'
  },
  inputField:{
    fontSize:12,
    color:'#21A6FC',
    alignItems:'center',
    fontWeight:'300',
    width:'100%',
    height:'30px',
    borderColor:'#21A6FC',
    borderStyle:'solid',
    borderWidth:'1px',
    borderRadius:'5px',
  },
  button:{
    fontSize:12,
    color:'white',
    backgroundColor:'#21A6FC',
    fontWeight:'300',
    width:'100px',
    borderColor:'#21A6FC',
    borderRadius:'30px',
    justifyContent:'center',
  },
  buttonOutlined:{
    fontSize:12,
    color:'#21A6FC',
    
    fontWeight:'300',
    width:'100px',
    borderColor:'#21A6FC',
    borderRadius:'30px',
    justifyContent:'center',
    marginLeft:'100px'
  },
  inputLabel:{
    fontSize:12,
    color:'#21A6FC',
    display:'block',
    fontWeight:'300',
  },
  body : {
    
    color:'#C7DCFF',
    backgroundColor:'#F7FAFF',
    height:'100%',
    width: '100%',
    position: 'absolute', left: '50%', top: '50%',
    transform: 'translate(-50%, -50%)',
    borderStyle:'solid',
    borderWidth:'1px',
    borderRadius:'30px',
    boxShadow:'1px 1px 10px',
    justifyContent:'center',
  },

  divForm:{
    color:'#C7DCFF',
    backgroundColor:'#F7FAFF',
    height:'60%',
    width:'70%',
    position: 'absolute', left: '50%', top: '50%',
    transform: 'translate(-50%, -50%)',
    //borderStyle:'solid',
    //borderWidth:'1px',
   // borderRadius:'30px',
   // boxShadow:'1px 1px 10px',
    textAlign:'left',
    alignItems:'center',
    alignContents:'center',
  }
}
export default LoginView;
