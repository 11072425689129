/* eslint-disable no-unused-expressions */

import React from 'react';
import { useHistory } from "react-router-dom";
import { useState, useEffect} from 'react';
import {db,firebase} from './modules/Firestore';
import useGlobal from './Store';
import { Container,DropdownButton,Dropdown,Col,Row,Jumbotron } from 'react-bootstrap';

import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';
import EventDetails from './EventDetails';
import Header from './modules/Header';


function MainView(props) {


  const history = useHistory();
  const [globalState, globalActions] = useGlobal();
  const [events,setEvents] = useState([]);
  const [token,setToken] = useState([]);

  useEffect(() => {
   
    document.title = "RacetrackerMap";

    globalActions.setUserId(props.match.params.userId)
    console.log(props.match.params.userId);
    loadEvents();
    console.log('use effect called');

  },[globalActions]);

  const loadEvents = () => {

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log('user is logged');
      }
    });
    
    const userId = props.match.params.userId;
   // const userToken = props.match.params.token;

    if(userId === '') {
      const temp = [{
        id:'1',
        name:'no events'
      }];
      setEvents(temp);
      return;
    } 

    const dbRef = db.collection("events");

    var date = new Date()
    date -= (24 * 60 * 60 * 1000);

    dbRef.doc(userId).collection('eventItems').get().then((documents) => {
        var data = Array()
        documents.forEach((doc) => {
          
          var eventDate = doc.data()['date'].toDate()
          console.log(eventDate)
            
          if(eventDate.getTime() > date) {
            var item = {
                'id':doc.id,
                'name':doc.data()['name'],
                'date':doc.data()['date']
            }
            data.push(item)
          }
        })
        setEvents(data)
        console.log(data)
    })

    /*
    dbRef.doc(userId).get().then((document)=>{
        if (document.exists === false) {
            console.log('No matching documents.');
            return;
        }
        else {
          
          console.log(document.data());
          const events = document.data()['eventItems']
          const temp = events.map((event) => {
              return {
                'id':,
                'name':event.name,
                'date':event.date
              }
          })
          setEvents(temp);
          console.log(temp); 

        }  
    })*/
  }
  const onDropdownSelect = (key,obj) => {
      console.log(key);
      if(key !== '1' && typeof(key) == 'string') {
        globalActions.setEventId(key);
        history.push('/eventDetails/true',{key:key,userId:props.match.params.userId});
      }
     

  };
  const onCreateNewEvent = () => {
      history.push('/createEvent',{userId:props.match.params.userId});
  };

  const EventList = () => {
     return (
     <DropdownButton onSelect={(key,obj)=>{onDropdownSelect(key,obj)}} title='Select Event to view' style={{marginBottom:'10px'}}>
        {
          events.map((event)=>( 
            <Dropdown.Item eventKey={event.id} onClick={onDropdownSelect}>{event.name}</Dropdown.Item>
          ))
        }
      </DropdownButton>
    
    );
  };

  return (
  <Container style={{display:'flex',flexDirection:'column',alignItems:'center' }}>
    <Header headerText="RaceTracker"></Header>
    <Row >
      <Col sm style={{display:'flex',flexDirection:'column',alignItems:'center' }} >
        <EventList></EventList>
      </Col>
      <Col sm style={{display:'flex',flexDirection:'column',alignItems:'center' }}>
        <Button variant="secondary" onClick={onCreateNewEvent}>Create a new Event</Button>
      </Col>
    </Row>
  </Container>  
  );
  
}
const styles = {

  mainDiv:{
    width:'100%',
    height:'100%',
  },
  mainHeading:{
    fontSize:25,
    fontWeight:'600',
    color:'black',
    display:'block',
    marginTop:'30px',
    textAlign:'left'
  },
  heading : {
    fontSize:20,
    fontWeight:'600',
    color:'#21A6FC',
    display:'block',
    marginTop:'30px',
    textAlign:'center'
  },
  inputField:{
    fontSize:12,
    color:'#21A6FC',
    alignItems:'center',
    fontWeight:'300',
    width:'100%',
    height:'30px',
    borderColor:'#21A6FC',
    borderStyle:'solid',
    borderWidth:'1px',
    borderRadius:'5px',
  },
  button:{
    fontSize:12,
    color:'white',
    backgroundColor:'#21A6FC',
    fontWeight:'300',
    width:'50%',
    height:'40px',
    borderColor:'#21A6FC',
    borderStyle:'solid',
    borderWidth:'1px',
    borderRadius:'30px',
    position: 'absolute', left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  inputLabel:{
    fontSize:12,
    color:'#21A6FC',
    display:'block',
    fontWeight:'300',
  },
  body : {
    
    color:'#C7DCFF',
    backgroundColor:'#F7FAFF',
    height:'400px',
    width:'500px',
    position: 'absolute', left: '50%', top: '50%',
    transform: 'translate(-50%, -50%)',
    borderStyle:'solid',
    borderWidth:'1px',
    borderRadius:'30px',
    boxShadow:'1px 1px 10px',
    justifyContent:'center',
  },

  divForm:{
    color:'#C7DCFF',
    backgroundColor:'#F7FAFF',
    height:'60%',
    width:'70%',
    position: 'absolute', left: '50%', top: '50%',
    transform: 'translate(-50%, -50%)',
    //borderStyle:'solid',
    //borderWidth:'1px',
   // borderRadius:'30px',
   // boxShadow:'1px 1px 10px',
    textAlign:'left',
    alignItems:'center',
    alignContents:'center',
  },
  button : {
    fontSize:'15px',
    width:'120px',
    marginLeft:'20px',
    marginTop:'30px',
    backgroundColor:'#21A6FC',
    padding:'10px',
    textAlign:'center',
    borderRadius:'20px',
    color:'white',
    cursor:'pointer',
   
}
}
export default MainView;
