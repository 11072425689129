import React from "react";
import globalHook from 'use-global-hook';

const initialState = {
   userId:'',
   eventId:'',
   email:''
};

const actions = {
    setUserId: (store, userId) => {
      store.setState({ userId: userId });
    },
    setEventId: (store, eventId) => {
        store.setState({eventId: eventId});
    },
    setUserEmail: (store, email) => {
      store.setState({ email: email });
    }


};

const useGlobal = globalHook(React, initialState, actions);

export default useGlobal;