import React, { Component } from 'react';
import icon from './images/icon.png';
import { withRouter } from 'react-router-dom';
import {Map, Marker, GoogleApiWrapper, Polyline,InfoWindow,showInfoWindow} from 'google-maps-react';
import {Col } from 'react-bootstrap';

class SimpleMap extends Component {


  state = {
    locations:[],
    polyData:this.props.polyData,
    userData:this.props.data,
    zoom:12,
    center: {
      lat: 19.56,
      lng: 73.36
    },
    isMapDataAvailable:false,
    token:'',
    searchedUser:'',
    height:this.props.height,
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    selectedMarkerTitle:""
  };
  
  static defaultProps = {
    center: {
      lat: -31.56,
      lng: 150.36
    },
    zoom: 12
  };
  constructor(props) {
    super(props);

    this.state = {
      locations:[],
      userData:this.props.data,
      polyData:this.props.polyData,
      height:this.props.height

    };
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    console.log("data: "+this.props.data);
    if(prevProps.data !== this.props.data || prevProps.searchedUser !== this.props.searchedUser) {
      console.log("component mounted");
      this.setState(
        {
          userData:this.props.data,
          polyData:this.props.polyData
        }
      );

      const data = this.props.data;  
      const user = this.props.searchedUser
      
      if(user == '') {
        const locData = data.map((userData) => {
          return {
            location:{
              'lat':userData.location.latitude,
              'lng':userData.location.longitude
            },
            title:userData.id
          }
        });

        this.setState({
          locations:locData,
          isMapDataAvailable:true,
          center:this.props.center,
        });
      }
      else {
        var locData = []
        data.forEach((userData) => {
          if(userData.id == user) {
            var temp = {
                location:{
                  'lat':userData.location.latitude,
                  'lng':userData.location.longitude
                },
                title:userData.id
            }
            locData.push(temp);
          }
        });

        this.setState({
          searchedUser:this.props.searchedUser
        })
        console.log(locData);
        this.setState({
          locations:locData,
          isMapDataAvailable:true,
          center:this.props.center,
        
        });
      }
     
      const token = setTimeout(this.timeout, 5000)
      this.setState({
        token:token
      }) 
    }
  }
  timeout = () => {
      clearTimeout(this.token)
      console.log('timeout')
      this.setState({
        zoom:12
      })
  }
  
  onChildClick = (key, childProps) => {
    console.log('child click:'+key)
    console.log(childProps.title)

  }

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
      selectedMarkerTitle:marker.title
    });
 
  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  };
  render() {

      return (
        // Important! Always set the container height explicitly
        <Col style={{ height: this.state.height ? undefined : '100vh', width: '100%'}} className = "my-auto">
        
          { this.state.isMapDataAvailable && 
          <Map google={this.props.google}
              initialCenter={this.props.center}
              zoom={this.state.zoom}
              onChildClick={this.onChildClick}
              center={this.props.center}
          >
          {
            this.state.locations.map((userLoc) => (
            
            <Marker position={{lat: userLoc.location.lat, lng: userLoc.location.lng}} title={userLoc.title}
                onClick={this.onMarkerClick}
                name={userLoc.title}/>
            ))
          }
          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}>
              <div>
                {this.state.selectedMarkerTitle}
              </div>
          </InfoWindow>
          <Polyline 
            path={this.state.polyData} 
            options={{ 
            strokeColor: 'red',
            strokeOpacity: 1,
            strokeWeight: 2,
          }}
          />
          </Map>
          }
        </Col>
      )
  }
}
 
export default GoogleApiWrapper({
  apiKey: 'AIzaSyA_oi0vLZqua3CICi-moS_NZX2ZA2ldLqo'
})(SimpleMap)