import React from 'react';
import logo from './logo.svg';
import './App.css';
import './Map.js';

import {Route, Switch } from 'react-router-dom';
import EventDetails from './EventDetails';
import LoginView from './LoginView';
import MainView from './MainView';
import CreateEvent from './CreateEvent';
import { register } from './serviceWorker';
import Register from './Register';
import GlobalEvents from './GlobalEvents';

function App() {
  return (
    <main>
      <Switch>
        <Route path="/" component={GlobalEvents} exact/>
        <Route path="/eventDetails/true" component={EventDetails} />
        <Route path="/login" component={LoginView} exact />
        <Route path="/main/:userId" component={MainView} />
        <Route path="/eventDetails/:isMultiEvent" component={EventDetails} />
        <Route path="/createEvent" component={CreateEvent} />
        <Route path="/register" component={Register} />
      </Switch>
    </main>
  );
}

export default App;
