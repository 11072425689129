import React from 'react';
import { useHistory } from "react-router-dom";
import { useState,useEffect } from 'react';
import {firebase} from './modules/Firestore'


import Jumbotron from 'react-bootstrap/Jumbotron';
import Toast from 'react-bootstrap/Toast';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { Form,Col,Alert } from 'react-bootstrap';
import useGlobal from './Store';
import Global from './Global';
import Header from './modules/Header';


function LoginView() {

  const [username,setUsername] = useState();  
  const [pwd,setPwd] = useState();
  const history = useHistory();
  const [globalState, globalActions] = useGlobal();
  const [userId,setUserId] = useState('');
  const [showAlert,setShowAlert] = useState(false);
  const [alert,setAlert] = useState({
      message:'',
      alertType:'' 
  });
 

  useEffect(()=>{
    document.title = "RacetrackerMap";
  });
  const submitHandler = () => {
    console.log(username+' '+pwd);
   
    if(typeof username === 'undefined' || typeof pwd === 'undefined') {
        setShowAlert(true);
        setAlert({
            message:'Invalid username/password',
            alertType:'failure' 
        });
        return;
    }
    //history.push('/main');
    firebase.auth().createUserWithEmailAndPassword(username, pwd).then((result) => {
        setShowAlert(true);
        setAlert({
            message:'Registration successful!',
            alertType:'success' 
        });
    }).catch(function(error) {
        // Handle Errors here.
        setShowAlert(true);
        setAlert({
            message:error.message,
            alertType:'failure' 
        });
    });
  }
  
const closeAlert = () => {
   setShowAlert(false); 
   history.goBack();   
}  
function AlertDismissible(props) {
    return (
        <>
        <Alert show={showAlert} variant={props.type}>
            
            <p>
            {props.message}
            </p>
            <hr />
            <div className="d-flex justify-content-end">
            <Button onClick={closeAlert} variant="outline-success">
                Close
            </Button>
            </div>
        </Alert>
        </>
        );
}
   
  
return (
     
  <Container >
  <Header headerText="RaceTracker"></Header>
  { showAlert == false &&
  <Col>
    <Jumbotron style = {{padding:'50px'}}>
        <Form>
        <Form.Group controlId="formBasicEmail">
            <Form.Label>User name / Email</Form.Label>
            <Form.Control type="email" value={username} placeholder="Enter email" onChange={(e)=>{setUsername(e.target.value)}} />
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" value={pwd} placeholder="Enter Password" onChange={(e)=>{setPwd(e.target.value)}}/>
        </Form.Group>
        <div className="d-flex justify-content-center">
            <Button style={styles.button} onClick={submitHandler}>
            Register
            </Button>
        
        </div>
        </Form>
        </Jumbotron>
    </Col>
  }
     {showAlert && <AlertDismissible type={alert.type} message={alert.message} ></AlertDismissible> }
  </Container>
);
  
}
const styles = {
  
  mainDiv:{
    width:'100%',
    height:'100%',
  },
  mainHeading:{
    fontSize:25,
    fontWeight:'600',
    color:'black',
    display:'block',
    marginTop:'30px',
    textAlign:'left'
  },
  heading : {
    fontSize:20,
    fontWeight:'600',
    color:'#21A6FC',
    display:'block',
    marginTop:'30px',
    textAlign:'center'
  },
  inputField:{
    fontSize:12,
    color:'#21A6FC',
    alignItems:'center',
    fontWeight:'300',
    width:'100%',
    height:'30px',
    borderColor:'#21A6FC',
    borderStyle:'solid',
    borderWidth:'1px',
    borderRadius:'5px',
  },
  button:{
    fontSize:12,
    color:'white',
    backgroundColor:'#21A6FC',
    fontWeight:'300',
    width:'100px',
    borderColor:'#21A6FC',
    borderRadius:'30px',
    justifyContent:'center',
  },
  buttonOutlined:{
    fontSize:12,
    color:'#21A6FC',
    
    fontWeight:'300',
    width:'100px',
    borderColor:'#21A6FC',
    borderRadius:'30px',
    justifyContent:'center',
    marginLeft:'100px'
  },
  inputLabel:{
    fontSize:12,
    color:'#21A6FC',
    display:'block',
    fontWeight:'300',
  },
  body : {
    
    color:'#C7DCFF',
    backgroundColor:'#F7FAFF',
    height:'100%',
    width: '100%',
    position: 'absolute', left: '50%', top: '50%',
    transform: 'translate(-50%, -50%)',
    borderStyle:'solid',
    borderWidth:'1px',
    borderRadius:'30px',
    boxShadow:'1px 1px 10px',
    justifyContent:'center',
  },

  divForm:{
    color:'#C7DCFF',
    backgroundColor:'#F7FAFF',
    height:'60%',
    width:'70%',
    position: 'absolute', left: '50%', top: '50%',
    transform: 'translate(-50%, -50%)',
    //borderStyle:'solid',
    //borderWidth:'1px',
   // borderRadius:'30px',
   // boxShadow:'1px 1px 10px',
    textAlign:'left',
    alignItems:'center',
    alignContents:'center',
  }
}
export default LoginView;
