import React, { StrictMode} from 'react';
import { useHistory } from "react-router-dom";
import { useState,useEffect } from 'react';
import useGlobal from './Store';
import {db,firebase} from './modules/Firestore';

import SimpleMap from './Map';
import Button from 'react-bootstrap/Button';
import {TextField} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import GPX from 'gpx-parser-builder';


import {Container,Form,Col,Row,DropdownButton,Dropdown} from 'react-bootstrap';
import Header from './modules/Header';
import { WrapText } from '@material-ui/icons';

function GlobalEvents(props) {

    var nameInput = React.createRef();
    const [name,setName] = useState('');
    const [searchText,setSearchText] = useState('')
    const [eventData,setEventData] = useState([]);
    const [mapCenter,setMapCenter] = useState(0);
    const [noData,setNoData] = useState(true);
    const [searchedUser,setSearchedUser] = useState('');
    const [isMultiEvents,setIsMultiEvents] = useState(false)    
    const [multiEventData,setMultiEventData] = useState([]);
    const [globalState, globalActions] = useGlobal();
    const history = useHistory();
    const [token,setToken] = useState('')
    const [selectedEvent,setSelectedEvent] = useState(null)
    const [peopleCount,setPeopleCount] = useState(0)
    const [messageState,setMessageState] = useState(false)
    const [email,setEmail] = useState('')
    const [polyData,setPolyData] = useState('')

    var dbRef

    useEffect(() => {
        document.title = "RacetrackerMap";
        tempAuth();
        

        if(multiEventData.length > 0) {
            let obj = multiEventData[0]
            setSelectedEvent(obj)
        }
         
    },[]); 

    const tempAuth = () => {
       
        firebase.auth().signOut();
        firebase.auth().signInWithEmailAndPassword('temp@racetrackermap.com',"temp12345").catch(function(error) {
            // Handle Errors here.
            var errorMessage = error.message;
            console.log(errorMessage);
            alert(errorMessage);
        });
      
        firebase.auth().onAuthStateChanged(async userAuth => {
        if(userAuth) {
            console.log('logged in');
            let userId = userAuth.uid;
            console.log('Userid:',userId);
            globalActions.setUserId(userId);
            setEmail(userAuth.email)
            loadMultiEventData()
        }
    });
    }
    const loadEventData = (eId,uId) => {

        const eventId = eId 
        const userId = uId
       
        const dbRef = db.collection("events");
        dbRef.doc(userId).collection('eventItems').doc(eventId).get().then((snap)=>{

            if (snap.empty) {
                console.log('No matching documents.');
                return;
            }
            else {
                console.log(snap.data());
                const data = snap.data();
                const userData = data['userData'];
                var gpxData = data['gpxData'];
                var tempGpx = [];

                if(gpxData === null || gpxData === 'undefined' || gpxData.length === 0) {
                    gpxData = [];
                }
                else {
                    const gpx = GPX.parse(gpxData);
                    console.log(gpx);
                    if(gpx != undefined) {
                        const trk = gpx.trk[0];
                        const trkSeg = trk.trkseg[0];

                        
                        for(var i = 0; i < trkSeg.trkpt.length; i++) {
                            const wpt = trkSeg.trkpt[i];
                            console.log(wpt.$.lat + " " + wpt.$.lon);
                            const item = {'lat':parseFloat(wpt.$.lat),'lng':parseFloat(wpt.$.lon)};
                            tempGpx.push(item);
                        }
                        setPolyData(tempGpx);
                    }
                }

                if(userData === null || userData === 'undefined' || userData.length === 0) {
                    //setNoData(true);
                    setEventData(userData);
                    const center = {
                        lat:51.509865,
                        lng:-0.118092
                    }
                    setMapCenter(center);
                    setPeopleCount(0)
                    
                }
                const user = userData[0];
                setPeopleCount(userData.length)
                console.log(userData);
                setEventData(userData);

                if(user != undefined) {

                    const center = {
                        lat:user.location.latitude,
                        lng:user.location.longitude
                    }
                    setMapCenter(center);
                }
                else if(tempGpx.length > 0){
                    const center = {
                        lat:tempGpx[0].lat,
                        lng:tempGpx[0].lng
                    }
                    setMapCenter(center);
                }
            }  
        })

        if(userId === '' || eventId === '') {
            setNoData(true);
            return;
        }
        else {
            setNoData(false);
        }
    }
    const onDropdownSelect = (key,obj) => {
        console.log(key);
        if(key !== '1' && typeof(key) == 'string') {
          //globalActions.setEventId(key);
          let obj = multiEventData.find(o => o.eventItemId === key);  
          loadEventData(obj.eventItemId,obj.userId)  
          setSelectedEvent(obj)
        }
    };

    const timeout = () => {
        clearTimeout(token)
        console.log('timeout')
        setNoData(false)
        /*this.setState({
          zoom:12
        })*/
    }
    Date.prototype.addHours = (h) => {
        this.setHours(this.getHours()+h);
        return this;
    }
    const loadMultiEventData = async () => {

        setMessageState(true)    
        var tempArray = []
        var eventId = []
        var db = firebase.firestore();
        const eventsRef = await db.collection("events").get() 
        
        eventsRef.forEach((doc) => {
            eventId.push(doc.id)
            console.log('event:'+doc.id)
        })   
        var eventItem = []
        for(var i = 0; i< eventId.length; i++) {
            const itemsRef = await db.collection("events").doc(eventId[i]).collection('eventItems').where("type", "==", "1").get()
            itemsRef.forEach((item) => {
                console.log('event item:'+item.id)
                var item = {
                    id:eventId[i],
                    eventItemId:item.id
                }
                eventItem.push(item)
            })
        }

        var allEvents = []

        var date = new Date()
        date -= (24 * 60 * 60 * 1000);
        console.log('backdate:' + date)
        for(var i = 0; i < eventItem.length; i++) {
            const eItem = eventItem[i]
            
            const doc = await db.collection("events").doc(eItem.id).collection('eventItems').doc(eItem.eventItemId).get()

            var eventDate = doc.data()['date'].toDate()
            console.log(eventDate)
            
            if(eventDate.getTime() > date) {

                var item = {
                    userId:eItem.id,
                    userData:doc.data()['userData'],
                    name:doc.data()['name'],
                    location:doc.data()['location'],
                    eventItemId:doc.id,
                }
                allEvents.push(item)
            }
        }
        setMessageState(false)
        setMultiEventData(allEvents)
        
    }
    const searchEvent = (event) => {
        multiEventData.forEach((eventItem) => {
            var item = eventItem
            if(item.name.includes(event) === true) {
                loadEventData(item.eventItemId,item.userId) 
                setSelectedEvent(item) 
            }
        })
    }
    const onClickSearch = () => {
         searchEvent(name.name)
    };

    const onClick = () => {
        history.goBack()
    };

    const onClickSearchUser = () => {
        setSearchedUser(searchText)
    }
    return (
        <Container >
        <Header headerText="RaceTracker" email={email}></Header>
        { messageState &&
        <Row>
            <Col>
                Please wait while loading data....
            </Col>
        </Row>
        }
        
        <Row style={{display:'flex',alignItems:'center'}} >  
            <Col>
                <Autocomplete
                    id="combo-box-demo"
                    options={multiEventData}
                    getOptionLabel={(option) => option.name}
                    style={{ borderColor:'black'}}
                    freeSolo
                    renderInput={(params) => <TextField {...params} label="Search for an event" variant="outlined" />}
                    onChange={(event, newValue) => {
                        if(newValue != null) {
                            setName(newValue)
                            console.log(newValue.name)
                        }
                    }}
                />
            </Col>

            <Col style={{display:'flex',alignItems:'center'}}>
                <Form >
                    <Form.Row  style={{height:'40px'}}>
                        
                        <Col>
                            <Button variant="primary" onClick={onClickSearch}>
                                Search
                            </Button>
                        </Col>
                    </Form.Row>
                </Form>
            </Col>
            <Col sm>
                <Form >
                    <Form.Row  style={{width:'300px', height:'55px', display:'flex',alignItems:'center'}}>
                        <Col>
                            <Form.Control  type="input" placeholder="User Number" onChange={(e)=>{setSearchText(e.target.value)}} value={searchText} style={{height:'55px'}}/>
                        </Col>
                        <Col>
                            <Button variant="primary" onClick={onClickSearchUser} style={{marginLeft:'20px'}}>
                                Search
                            </Button>
                        </Col>
                    </Form.Row>
                </Form>
                
            </Col>   
           
        </Row>
        <Row>
            <Col>
                <label style={{ fontSize:'20'}}>People Count:</label> <label style={{fontSize:'30px'}}>{peopleCount}</label>
            </Col>
        </Row>
        <SimpleMap data={eventData} searchedUser={searchedUser} center={mapCenter} polyData={polyData}></SimpleMap> 
        </Container>
    );
   
}
export default GlobalEvents;
